import { z } from "zod";
import { DeepPartial } from "redux";
import { formatValidateEmailList, validateEmailList } from "shared/utils";
import { ValueOf } from "ts-essentials";
import { policiesFormDefaults } from "shared/dashboard";
import {
  types_CategoryStatusEnum,
  types_IncidentActionEnum,
  types_SearchFieldEnum,
  types_SelectionTypeEnum,
  riskydashboard,
  types,
} from "api/gen";
import { schemaToFinalFormError } from "libs/forms";
import { Severity } from "modules/risks/dashboard-core/severity";
import { rulesSchema } from "../search/search.schema";
import { getFieldMinRequiredVersion, isAllowedField } from "./fieldValidation";
import { isEmailNotificationSupported } from "../dashboard/utils";

function emailListRefinement(val: string) {
  const emailErrors = validateEmailList(val);
  if (emailErrors.length > 0) {
    return formatValidateEmailList(emailErrors);
  }
  return "";
}

export const getPolicyDefault = (): types.Category => {
  return {
    name: "",
    selection_type: "dataset",
    dataset_sensitivities: [],
    dataset_ids: [],
    severity: Severity.Low,
    exclude_origin: false,
    query: {
      rules: [],
    },
    rule: {
      ...policiesFormDefaults,
      create_incident: false,
      notify_email: "",
      notify_enabled: false,
      incident_action: types_IncidentActionEnum.IncidentActionNone,
      notify_status: "",
      status: types_CategoryStatusEnum.CategoryStatusRisky,
    },
  };
};

export const datasetDefaults: DeepPartial<riskydashboard.DatasetDTO> = {
  name: "",
  category_ids: [],
  sensitivity: 1,
};

export const querySchema = z
  .object({
    rules: rulesSchema,
  })
  .optional();

export const policySchema = z
  .object({
    id: z.string().optional(),
    last_modified: z.string().optional(),
    name: z.string().min(2),
    severity: z.number().optional(),
    dataset_ids: z.array(z.string()).optional(),
    dataset_sensitivities: z.array(z.number()).optional(),
    exclude_origin: z.boolean().default(false),
    include_non_movement: z.boolean().optional().default(false),
    query: querySchema,
    selection_type: z
      .nativeEnum(types_SelectionTypeEnum)
      .default(types_SelectionTypeEnum.SelectionTypeDataset),
    user_query: querySchema,
    rule: z
      .object({
        create_incident: z.boolean().optional(),
        disable_ai_incidents: z.boolean().optional(),
        notify_email: z.string().optional(),
        notify_enabled: z.boolean(),
        incident_action: z
          .nativeEnum(types_IncidentActionEnum)
          .default(types_IncidentActionEnum.IncidentActionNone),
        status: z.string(),
      })
      .passthrough()
      .refine(
        (v) => {
          if (!v.notify_enabled || !isEmailNotificationSupported(v as any)) {
            return true;
          }
          return !emailListRefinement(v.notify_email!);
        },
        (v) => ({
          path: ["notify_email"],
          message: emailListRefinement(v.notify_email!),
        })
      ),
    last_successful_backfill: z.string().datetime().optional(),
    backfill_window: z.string().datetime().optional(),
  })
  .passthrough();

interface ParseOptions {
  minEndpointVersion?: string;
  validateFields?: boolean;
  entityType?: "policy" | "dataset";
  pathPrefix?: string[];
}

export function parsePolicy(
  value: types.Category,
  options: ParseOptions = {}
): {
  values: types.Category | null;
  errors: Record<any, any> | null;
} {
  const schema = options.validateFields
    ? policySchema.superRefine((v, ctx) => {
        validateFieldsSelectedType(v as any, ctx);
        return validateFields(v as any, ctx, { ...options, entityType: "policy" });
      })
    : policySchema;
  policySchema.superRefine((v, ctx) => {
    validateFieldsSelectedType(v as any, ctx);
  });

  if (!isEmailNotificationSupported(value.rule)) {
    value.rule.notify_email = "";
    value.rule.notify_enabled = false;
  }

  const values = schema.safeParse(value as any);

  if (!values.success) {
    return {
      values: null,
      errors: schemaToFinalFormError(values.error),
    };
  }
  // @ts-ignore
  delete values.data.include_non_movement;
  return {
    errors: null,
    values: values.data as any,
  };
}

export const datasetSchema = z
  .object({
    id: z.string().optional(),
    last_modified: z.string().optional(),
    name: z.string().min(2),
    category_ids: z.array(z.string()).nullish(),
    query: z
      .object({
        rules: rulesSchema.optional(),
      })
      .optional(),
  } as any)
  .passthrough();

export function parseDataset(value: riskydashboard.DatasetDTO, options: ParseOptions = {}) {
  const schema = options.validateFields
    ? datasetSchema.superRefine((v, ctx) =>
        validateFields(v as any, ctx, { ...options, entityType: "dataset" })
      )
    : datasetSchema;
  const values = schema.safeParse(value as any);
  if (values.success === false) {
    return {
      values: null,
      errors: schemaToFinalFormError(values.error),
    };
  } else {
    return {
      errors: null,
      values: values.data as any,
    };
  }
}

export function setDatasetDefaults(value: any) {
  return value || datasetDefaults;
}

export const checkDatasetContainsCategoryType = ({
  ids,
  categories,
  type,
}: {
  ids?: string[];
  categories: types.Category[];
  type: ValueOf<typeof types_IncidentActionEnum>;
}) => {
  const idsSet = new Set(
    categories.filter(({ rule }) => rule.incident_action === type).map(({ id }) => id)
  );
  return (ids || []).some((catId) => idsSet.has(catId));
};

function validateFieldsSelectedType(
  entity: Partial<Pick<types.Category, "selection_type" | "dataset_ids" | "dataset_sensitivities">>,
  ctx: z.RefinementCtx
) {
  if (
    entity?.selection_type === types_SelectionTypeEnum.SelectionTypeDataset &&
    !entity.dataset_ids?.length
  ) {
    ctx.addIssue({
      code: "custom",
      path: ["dataset_ids"],
      message: "Should have at least 1 dataset selected",
    });
  } else if (
    entity?.selection_type === types_SelectionTypeEnum.SelectionTypeSensitivity &&
    !entity.dataset_sensitivities?.length
  ) {
    ctx.addIssue({
      code: "custom",
      path: ["dataset_sensitivities"],
      message: "Should have at least 1 sensitivity selected",
    });
  }
}

export function validateFields(
  entity: Partial<Pick<types.Category, "query" | "user_query">>,
  ctx: z.RefinementCtx,
  { minEndpointVersion, entityType, pathPrefix = [] }: ParseOptions = {}
) {
  const searchFields = new Set<string>(Object.values(types_SearchFieldEnum));

  [entity?.query, entity?.user_query]?.forEach((query, index) =>
    query?.rules?.forEach((rule, ruleIndex) => {
      rule.conditions?.forEach((condition, conditionIndex) => {
        // Report fields that are not supported for particular endpoint versions
        if (
          searchFields.has(condition.field_name) &&
          !isAllowedField(condition.field_name, { minEndpointVersion })
        ) {
          const pathRoot = index ? "user_query" : "query";
          ctx.addIssue({
            code: "custom",
            path: [
              ...pathPrefix,
              pathRoot,
              "rules",
              ruleIndex,
              "conditions",
              conditionIndex,
              "field_name",
            ],
            message: "Field is not supported",
            params: {
              minRequiredVersion: getFieldMinRequiredVersion(condition.field_name),
              type: "endpoint_field_not_supported",
              entityType,
            },
          });
        }
      });
    })
  );
}
